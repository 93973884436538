import styled from 'styled-components'

export const CtaButton = styled.button`
  font-size: 1rem;
  line-height: 1;
  display: flex;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;

  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
  }

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  && > a {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`

export const LinkStyled = styled.a`
  color: ${({ theme }) => theme.palette.link.main};
  padding: 1rem;
`
