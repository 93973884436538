import { useMemo } from 'react'
import { Strokes } from 'store/slices/pg-slice/interface'
import { getStrokeAnalysis } from 'utils/pg/get-stroke-analysis'

import { Duration, RatioBar, StrokeAnalysisWrapper, StrokePanel, StrokeStat } from './stroke-analysis.styles'

export const StrokeAnalysis = ({
  strokes,
  duration,
  durationDecimalPlace,
}: {
  strokes: Strokes
  duration: number
  durationDecimalPlace: number
}) => {
  const { correct, wrong } = useMemo(() => {
    return getStrokeAnalysis(strokes)
    // make sure it only runs after the latest strokes is passed to this component because this calc is expensive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StrokeAnalysisWrapper>
      <Duration>
        <div>Duration</div>
        <div>{duration.toFixed(durationDecimalPlace)}s</div>
      </Duration>
      <StrokePanel>
        <StrokeStat>
          <div>Correctly typed characters</div>
          <div>{correct}</div>
        </StrokeStat>
        <StrokeStat>
          <div>Wrong characters</div>
          <div>{wrong}</div>
        </StrokeStat>
        <RatioBar percentage={correct / (correct + wrong)} />
      </StrokePanel>
    </StrokeAnalysisWrapper>
  )
}
