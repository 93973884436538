import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { PaletteMode } from 'components/app-theme-provider/interface'
import { getUserRef } from 'config/database/refs'
import { SETTINGS_DEFAULT } from 'constants/setting'
import firebase from 'firebase/compat/app'
import { DB_REF, DB_SETTINGS_ITEMS } from 'interface/database'
import { localStorageSetting } from 'local-store/setting'
import { actionCreators } from 'store/slices/setting'

// Keep adding properties if new one has been added in the database.
export interface SettingsProps {
  paletteMode: PaletteMode
  adResumeTimestamp: boolean
  showLiveStat: boolean
  showLiveWordPerf: boolean
  showProgressBar: boolean
  showTypingHands: boolean
  testDuration: number
}

/**
 * This utils syncs up the redux store with firebase store for the properties of `actionCreators` object.
 * To get it working on a page, you need to call useLoadSettings() hooks immediately
 * inside of authentication container, e.g. src\views\home\home.tsx
 * @param dispatch
 * @param uid
 */
export const loadSetting = async (dispatch: Dispatch<AnyAction>, uid?: string) => {
  const settingsProps = Object.keys(actionCreators) as Array<keyof typeof actionCreators>

  settingsProps.forEach((property) => {
    const stored = localStorageSetting(property as DB_SETTINGS_ITEMS)
    const defaults = SETTINGS_DEFAULT[property as DB_SETTINGS_ITEMS]
    dispatch(actionCreators[property](stored === null ? defaults : stored))
  })

  if (!uid) return

  const fireDb = await import('firebase/database')
  const { getDatabase, onValue, ref } = fireDb
  const db = getDatabase(firebase.app())

  settingsProps.forEach((property) => {
    const propertyRef = ref(db, getUserRef(uid, { dbRef: property as DB_REF }))
    onValue(
      propertyRef,
      (snapshot) => {
        dispatch(actionCreators[property](snapshot.val()))
      },
      (error) => {
        console.error('Error reading Firebase data: ', error)
      }
    )
  })
}
