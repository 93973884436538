import styled, { css, keyframes } from 'styled-components'

import { BackdropProps } from './interface'

const backdropAppear = keyframes`
  from { visibility: hidden; }  
  to { visibility: visible; }
`

const backdropDisappear = keyframes`
  from { visibility: visible; }  
  to { visibility: hidden; }
`

const visibleStyle = css<BackdropProps>`
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${backdropAppear} ${({ transitionDuration = 300 }) => transitionDuration}ms linear forwards;
`

const invisibleStyle = css<BackdropProps>`
  background-color: rgba(0, 0, 0, 0);
  animation: ${backdropDisappear} ${({ transitionDuration = 300 }) => transitionDuration}ms linear forwards;
`

export const Backdrop = styled.div<BackdropProps>`
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.tooltip - 1};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  transition: background-color 225ms;
  ${({ open }) => (open ? visibleStyle : invisibleStyle)};
  ${({ invisible = false }) => invisible && `background-color: rgba(0, 0, 0, 0);`}
`
