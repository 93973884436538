import styled, { css } from 'styled-components'

const CheckBoxSize = css<{ size: number }>`
  ${({ size }) =>
    size &&
    `
    width: ${size}px;
    height: ${size}px;
  `}
`

interface WrapperProps {
  isChecked?: boolean
  isDisabled?: boolean
  size: number
  indeterminate?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${CheckBoxSize};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1px 0 gray;
  cursor: pointer;
  opacity: 1;
  ${({ isDisabled = false }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ indeterminate = false, theme }) =>
    indeterminate &&
    css`
      background-color: ${theme.palette.border.light};
    `}
`
