import { css } from 'styled-components'

import { inputBase } from './input-base'

export const textareaStyles = css`
  ${inputBase};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2.5)};
  resize: vertical;
  border: 1px solid ${({ theme }) => theme.palette.border.light};
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text.primary};

  &::placeholder {
    color: ${({ theme }) => theme.palette.disabled.main};
  }

  &:active,
  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.border.main};
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`
