import type { AdAreaProps } from 'components/ad-area'
import { useIsTabletDown } from 'hooks/use-is-tablet-down'
import dynamic from 'next/dynamic'
import { ReactNode } from 'react'

import { Centered, Wrapper } from './with-ads.styles'

const LEFT_INS_ID = 'with-ads-left-ins'
const RIGHT_INS_ID = 'with-ads-right-ins'

const DynamicAdArea = dynamic<AdAreaProps>(() => import('components/ad-area').then((mod) => mod.AdArea), {
  ssr: false,
})

interface WithAdsProps {
  children: ReactNode
  hideAds?: boolean
}

const AD_GAP = 16
const AD_WIDTH_PLUS_MARGIN = 300 + AD_GAP

export const WithAds = (props: WithAdsProps) => {
  const { children, hideAds } = props

  const isTabletDown = useIsTabletDown()

  const showAds = !isTabletDown && !hideAds

  return (
    <Wrapper data-cy="with-ads-wrapper">
      {showAds && (
        <DynamicAdArea
          rightPadding={AD_GAP}
          leftMargin={-AD_WIDTH_PLUS_MARGIN}
          height={600}
          width={AD_WIDTH_PLUS_MARGIN}
          insId={LEFT_INS_ID}
        >
          <ins
            id={LEFT_INS_ID}
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-2438904058687691"
            data-ad-slot="4935437458"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </DynamicAdArea>
      )}

      <Centered>{children}</Centered>

      {showAds && (
        <DynamicAdArea
          insId={RIGHT_INS_ID}
          leftPadding={AD_GAP}
          rightMargin={-AD_WIDTH_PLUS_MARGIN}
          height={600}
          width={AD_WIDTH_PLUS_MARGIN}
        >
          <ins
            id={RIGHT_INS_ID}
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-2438904058687691"
            data-ad-slot="4935437458"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </DynamicAdArea>
      )}
    </Wrapper>
  )
}
