import { Modal } from 'components/modal'
import useToggle from 'hooks/use-toggle'
import { Button } from 'ui-kit/button'

import { AskAiModal } from './ask-ai-modal'

export const AskAi = () => {
  const { isOpen, handleClose, handleOpen } = useToggle()

  return (
    <div>
      <Button style={{ paddingLeft: '1.125rem' }} onClick={() => handleOpen()}>
        🤖 Ask AI
      </Button>

      <Modal fluid isOpen={isOpen} onClose={() => handleClose()} showCloseIcon>
        <AskAiModal handleClose={handleClose} />
      </Modal>
    </div>
  )
}
