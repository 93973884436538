import { AskAi } from 'components/ask-ai/ask-ai'
import { Loader } from 'components/loader'
import { IS_DEV } from 'constants/app'
import { PAGES } from 'constants/routes'
import { SITE_URL_ORIGIN } from 'constants/site'
import { TRAINING_NAMES } from 'constants/training'
import { usePersistedState } from 'context-providers/persisted-state'
import { useAppSelector } from 'hooks/use-app-selector'
import { useTraining } from 'hooks/use-training'
import { setLocalStoreLessonRules } from 'local-store/lesson'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useAuthUser } from 'next-firebase-auth'
import { useEffect, useState } from 'react'
import { Rules } from 'store/slices/pg-slice/interface'
import { selectAiSubscriptionLoading, selectIsAiSubscriptionActive } from 'store/slices/subscription/subscription'
import { Button } from 'ui-kit/button'
import { statIcon } from 'ui-kit/icons/stat-icon'

import { AskChatGptImageAction, TypingActionStyled } from './typing-action.styles'

export const TypingAction = ({
  handleNext,
  nextNotAllowed,
  nextIsLoading,
  typingFinished,
  forceNoAuth,
  rules,
  isAiLesson,
}: {
  handleNext: () => void
  nextNotAllowed: boolean
  nextIsLoading: boolean
  typingFinished: number | null
  forceNoAuth?: boolean
  rules: Partial<Rules>
  isAiLesson: boolean
}) => {
  const persistedState = usePersistedState()
  const router = useRouter()
  const authUser = useAuthUser()
  const loggedIn = !!authUser.id
  const authLoading = !authUser.clientInitialized

  const aiSubscriptionLoading = useAppSelector(selectAiSubscriptionLoading)
  const isAiSubscriber = useAppSelector(selectIsAiSubscriptionActive)

  const { setTriggerRef, setIsTrained, balloon } = useTraining({
    tooltipMessage: 'Press Esc to load next',
    trainingName: TRAINING_NAMES.ESC_FOR_NEXT,
  })

  const [shouldShowChatGptButton, setShouldShowChatGptButton] = useState(false)

  useEffect(() => {
    setShouldShowChatGptButton(isAiSubscriber ? false : persistedState.showChatGptBetaButton)
  }, [isAiSubscriber, persistedState.showChatGptBetaButton])

  return (
    <TypingActionStyled>
      {loggedIn && (
        <Button
          wrapperClass="gtm-pg-with-controls-typing-action-stats"
          onClick={() => !authLoading && router.push('/stats/')}
          title="Go to stats page"
        >
          <Loader
            name="components-pg-with-controls-typing-action-stats"
            placeholderWidth="3.658rem"
            loading={authLoading}
          >
            {statIcon}&nbsp; Stats
          </Loader>
        </Button>
      )}

      {!loggedIn && (
        <Button
          wrapperClass="gtm-pg-with-controls-typing-action-login-sign-up"
          onClick={() => {
            if (!forceNoAuth && authLoading) return

            // Set local storage for loading correct lesson name on reload
            setLocalStoreLessonRules({
              rules,
              pageRoute: '/typing-practice/',
            })
            const host = IS_DEV ? 'http://localhost:3000/' : SITE_URL_ORIGIN
            router.push(`/login/?destination=${encodeURIComponent(`${host}typing-practice/`)}`)
          }}
          title="Login or sign up"
        >
          <Loader
            name="components-pg-with-controls-typing-action-login-sign-up"
            placeholderWidth="3.658rem"
            loading={!forceNoAuth && authLoading}
          >
            Login / Sign up
          </Loader>
        </Button>
      )}

      {/* don't show AskChatGptImageAction if user is an AI subscriber */}
      {!authLoading && !aiSubscriptionLoading && shouldShowChatGptButton && (
        <AskChatGptImageAction>
          <>
            <NextImage
              width={178}
              height={48}
              title="Join Waitlist for Ask AI feature"
              onClick={() => router.push(PAGES['/ask-chat-gpt/'])}
              src="/ask-chat-gpt-beta-button.svg"
              alt=""
            />

            <div
              className="gtm-ask-chat-gpt-close-icon closeIcon"
              onClick={() => persistedState.setShowChatGptBetaButton(false)}
            >
              &times;
            </div>
          </>
        </AskChatGptImageAction>
      )}

      {/* don't show AskAi if user is an AI subscriber */}
      {!aiSubscriptionLoading && isAiSubscriber && isAiLesson && (
        <div style={{ marginLeft: 'auto' }}>
          <AskAi />
        </div>
      )}

      <Button
        wrapperClass="gtm-pg-with-controls-typing-action-next"
        data-cy="pg-with-controls-typing-action-next"
        title="Load next"
        ref={setTriggerRef}
        onClick={() => {
          handleNext()
          setIsTrained(true)
        }}
        disabled={nextNotAllowed}
      >
        <Loader
          name="components-pg-with-controls-typing-action-next"
          placeholderWidth="3.65rem"
          loading={nextIsLoading}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 448 512"
            width="1rem"
            height="1rem"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
          </svg>
          &nbsp; Next
        </Loader>
        {typingFinished && balloon}
      </Button>
    </TypingActionStyled>
  )
}
