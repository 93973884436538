import { LockBodyScroll } from 'components/lock-body-scroll'
import { MODAL_ID } from 'constants/ui'
import { useAppTheme } from 'hooks/use-app-theme'
import React, { ReactNode } from 'react'
import { Backdrop } from 'ui-kit/backdrop'
import { Portal } from 'ui-kit/portal'

import { CloseIcon, ModalContent, Wrapper } from './modal.styles'

interface Props {
  // makes the modal center on x and y
  center?: boolean
  // makes the modal 100vw and height minimum 100vh
  fluid?: boolean
  isOpen?: boolean
  onOverlayClick?: () => void
  showCloseIcon?: boolean
  children: ReactNode
  onClose?: () => void
}

const Modal = (props: Props) => {
  const { isOpen = false, center, fluid, onOverlayClick = () => '', children, onClose, showCloseIcon } = props
  const theme = useAppTheme()

  return (
    <>
      {!fluid && <Backdrop open={isOpen} />}
      {isOpen && <LockBodyScroll />}
      {isOpen && (
        <Portal id={MODAL_ID} zIndex={theme.zIndex.modal}>
          {/* mimic onOverlayClick on Wrapper since we can't put it on backdrop 
          because in that case backdrop hides the scroll bar of the following wrapper */}
          <Wrapper center={center} onClick={onOverlayClick}>
            {onClose && showCloseIcon && <CloseIcon onClick={onClose} />}
            <ModalContent fluid={fluid} onClick={(event) => event.stopPropagation()}>
              {children}
            </ModalContent>
          </Wrapper>
        </Portal>
      )}
    </>
  )
}

export { Modal }
