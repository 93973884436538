import { useAppDispatch } from 'hooks/use-app-dispatch'
import { useAuthUser } from 'next-firebase-auth'
import { useEffect } from 'react'
import { loadSetting } from 'utils/database/load-setting'

// use this hooks immediately inside of authentication container, e.g. src\views\home\home.tsx
export const useLoadSettings = () => {
  const dispatch = useAppDispatch()
  const { id } = useAuthUser()

  useEffect(() => {
    ;(async () => {
      if (id) await loadSetting(dispatch, id)
      if (!id) loadSetting(dispatch)
    })()
    // Safe to add dispatch to the dependencies array. Dispatch wont change.
  }, [dispatch, id])
}
