import styled from 'styled-components'
import { pageCenter } from 'styles/page-center.styles'

export const Wrapper = styled.div`
  ${pageCenter};
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  ${({ theme }) => theme.typography.body2};
`

export const MessageItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`

export const MessageRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;
`

export const MessageText = styled.span`
  padding-top: ${({ theme }) => theme.spacing(0.25)};
`

export const MessageHistory = styled.div`
  flex-grow: 1;
  overflow: auto;
  min-height: 200px;
  scroll-behavior: smooth;

  .ask-ai-message-item-avatar {
    flex-shrink: 0;
  }
`

export const Action = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  padding-right: 0;

  .send-ask-ai-message {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .ask-ai-message-limit-caption {
    width: 100%;
    margin-bottom: 0.5rem;
    opacity: 0.67;
  }
`

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`

export const MessageAction = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  div + div {
    margin-left: 0.5rem;
  }
`

export const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;

  textarea {
    padding-right: 108px;
    display: block;
  }
`
