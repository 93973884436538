// components/TypingEffect.tsx
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Cursor } from './typing-effect.styles'

interface TypingEffectProps {
  message: string
  scrollContainerRef?: React.RefObject<HTMLDivElement>
}

export const TypingEffect: React.FC<TypingEffectProps> = ({ message, scrollContainerRef }) => {
  const cursorIndex = useRef(0)

  const lastIndex = message.length - 1

  const [isTyping, setIsTyping] = useState(true)

  const [isCursorVisible, setIsCursorVisible] = useState(true)

  const messageRef = useRef<HTMLSpanElement>(null)

  const temporarilyHideCursor = useCallback(() => {
    setIsCursorVisible(false)

    const timer = setTimeout(() => {
      setIsCursorVisible(true)
    }, 500)

    return () => {
      // Cleanup function
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    const typeNextChar = () => {
      const currentCursorIndex = cursorIndex.current

      if (messageRef.current) {
        messageRef.current.textContent = message.slice(0, currentCursorIndex + 1)
      }

      if (currentCursorIndex < lastIndex) {
        cursorIndex.current = currentCursorIndex + 1

        const randomSpeed = 1 + Math.random() * 30 // Random typing speed between 20 and 120 ms
        const shouldPause = Math.random() < 0.05 // pausing
        const pauseDuration = shouldPause ? 750 : 0 // Pause duration is 500 ms

        temporarilyHideCursor()
        setTimeout(typeNextChar, randomSpeed + pauseDuration)
        setIsTyping(true) // Stop showing the cursor when typing is finished
      } else {
        setIsTyping(false) // Stop showing the cursor when typing is finished
      }
    }

    typeNextChar()

    return () => {
      // Cleanup function
    }
  }, [lastIndex, message, temporarilyHideCursor])

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight
    }
  }, [isCursorVisible, scrollContainerRef])

  return (
    <>
      <span ref={messageRef} style={{ paddingBottom: '64px' }} />

      {/* cursor */}

      {isTyping && isCursorVisible && <Cursor />}
    </>
  )
}
