import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
`

export const Centered = styled.div`
  flex-shrink: 0;
  width: 100%;
`
