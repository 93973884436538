import { useAppTheme } from 'hooks/use-app-theme'
import { usePortal } from 'hooks/use-portal'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface PortalProps {
  children: React.ReactNode
  id: string
  zIndex?: number
}

function Portal({ id, zIndex, children }: PortalProps) {
  const theme = useAppTheme()
  const target = usePortal(id, zIndex === undefined ? theme.zIndex.modal : zIndex)
  return createPortal(children, target)
}

export const ClientOnlyPortal = (props: PortalProps) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted ? <Portal {...props} /> : null
}
