import { useAppDispatch } from 'hooks/use-app-dispatch'
import { useAuthUser } from 'next-firebase-auth'
import { useEffect } from 'react'
import { loadAiSubscription } from 'utils/database/load-ai-subscription'

export const useLoadAiSubscription = () => {
  const dispatch = useAppDispatch()
  const { id } = useAuthUser()

  useEffect(() => {
    ;(async () => {
      if (id) await loadAiSubscription(dispatch, id)
    })()
    // Safe to add dispatch to the dependencies array. Dispatch wont change.
  }, [dispatch, id])
}
