import styled, { keyframes } from 'styled-components'

const blink = keyframes`

    0% {
      background: black;
    }
    1%{
        background-color: transparent;
    }
    39% {
      background: transparent;
    }
    40% {
      background: black;
    }
  `

export const Cursor = styled.div`
  height: 18px;
  width: 8px;
  position: relative;
  bottom: -2px;
  left: 2px;
  display: inline-block;
  background: black;
  animation: ${blink} 1s infinite;
`
