import React from 'react'

import { Wrapper } from './Checkbox.styles'

interface Props {
  checked?: boolean
  disabled?: boolean
  size?: number
  handleClick: () => void
  wrapperClass?: string
  indeterminate?: boolean
  id?: string
}

const Checkbox = (props: Props) => {
  const { checked, disabled: isDisabled, size = 16, handleClick, wrapperClass, indeterminate, id } = props

  return (
    <Wrapper
      indeterminate={indeterminate}
      isChecked={checked}
      isDisabled={isDisabled}
      onClick={handleClick}
      size={size}
      className={wrapperClass}
      id={id}
    >
      {!indeterminate && checked && <>&#10004;</>}
    </Wrapper>
  )
}

export { Checkbox }
