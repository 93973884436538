import styled, { css } from 'styled-components'
import { copyHolderPauseStyles } from 'styles/copy-holder-pause-styles'
import { copyHolderWrapperStyles } from 'styles/copy-holder-wrapper.styles'

export const Container = styled.div`
  padding: 0 2rem;
`

export const PgWrapper = styled.div`
  padding-top: 1.375rem;
  position: relative;
  ${({ theme }) => theme.typography.body1};
  margin-bottom: 3rem;
`

export const PgWrapperInside = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

export const StatsWrapper = styled.div``

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 0.375rem;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  overflow: hidden;
`

interface ProgressProps {
  progress: number
  forDuration: boolean
}

export const Progress = styled.div.attrs<ProgressProps>((props) => ({
  style: {
    width: `${props.progress}%`,
  },
}))<ProgressProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

// transition: all ${({ forDuration }) => (forDuration ? AUTO_PAUSE_CHECK_INTERVAL : 200)}ms linear;

export const OneMinuteWrapper = styled.div`
  user-select: none;
  display: flex;
  justify-content: space-between;
`
export const OneMinute = styled.div<{ isDisabled: boolean }>`
  margin-right: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.5rem;
  }

  .pg-action-one-minute {
    border: 1px solid ${({ theme }) => theme.palette.text.primary};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 0.75rem;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

export const PgHeadingWrapper = styled.div`
  h1 {
    display: inline;
    ${({ theme }) => theme.typography.body1}
  }
`

export const CopyHolderWrapper = styled.div`
  ${copyHolderWrapperStyles};

  border: 1px solid ${({ theme }) => theme.palette.success.light};
  box-shadow: 0 0 2px ${({ theme }) => theme.palette.success.light};
`

export const PauseNotification = styled.div`
  ${copyHolderPauseStyles};
  font-size: 125%;

  ${({ theme }) => css`
    ${theme.breakpoints.down.sm} {
      font-size: 1.5rem;
    }
  `}
`

export const PgHeading = styled.span``
