import styled from 'styled-components'

export const LoginAlertStyled = styled.div`
  padding: 0.5rem 0.75rem;
  background-color: #fff2cf;
  border-radius: 4px;
  border: 1px solid #f7e0a0;
  color: #88644e;
  font-size: '.875rem';
  display: flex;
  align-items: center;

  & > a:last-child {
    color: #0c907b;
  }
`
