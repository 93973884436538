import styled from 'styled-components'

export const TypingEndResult = styled.div`
  height: 3.5rem;
  padding: 0 1rem;
  border-radius: 4px;
  display: flex;
`
export const ResultNumber = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  font-size: 1.625rem;

  & sup {
    font-size: 0.625rem;
    position: relative;
    top: -0.5rem;
  }

  &:last-child {
    justify-content: flex-end;
  }
`
export const TypingRemarks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60%;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  padding: 0 0.25;
`
