import { useAppDispatch } from 'hooks/use-app-dispatch'
import { useAuthUser } from 'next-firebase-auth'
import { useEffect } from 'react'
import { loadAiMessageCountLast3Hours } from 'utils/database/load-ai-message-count-last-3-hours'

export const useLoadAiMessageCountLast3Hours = () => {
  const dispatch = useAppDispatch()
  const { id } = useAuthUser()

  useEffect(() => {
    ;(async () => {
      if (id) await loadAiMessageCountLast3Hours(dispatch, id)
    })()
    // Safe to add dispatch to the dependencies array. Dispatch wont change.
  }, [dispatch, id])
}
