import { MS_IN_HOUR } from 'constants/calculations'

/**
 *
 * @param typedLength length of the typed text
 * @param timeElapsed ms elapsed
 * @returns
 */
export const wpmToKeysPerHour = (typedLength: number, timeElapsed: number) => {
  if (timeElapsed === 0) return 0
  if (typedLength === 0) return 0

  return (typedLength / timeElapsed) * MS_IN_HOUR
}
