import { CtaLesson } from 'components/cta-lesson'
import { Loader } from 'components/loader'
import { TypingEffect } from 'components/typing-effect/typing-effect'
import { Typography } from 'components/typography'
import { AI_GUIDED_LESSON_BUTTON_TEXT, EVALUATION_BUTTON_TEXT, MAX_MESSAGE_COUNT_IN_3_HOURS } from 'constants/ai'
import { LESSON_NAMES } from 'constants/lessons'
import { PAGES } from 'constants/routes'
import { SentryPageTag } from 'constants/sentry'
import { useLoadAiMessageCountLast3Hours } from 'hooks/database/use-load-ai-message-count-last-3-hours'
import { useAppDispatch } from 'hooks/use-app-dispatch'
import { useAppSelector } from 'hooks/use-app-selector'
import { useAppTheme } from 'hooks/use-app-theme'
import { useAuthUser } from 'next-firebase-auth'
import { useCallback, useEffect, useRef } from 'react'
import {
  askAi,
  selectAiError,
  selectAiMessages,
  selectAiMessagesCountInLast3Hours,
  selectAiMessagesLoading,
  selectShouldShowAiGuidedLessonButton,
  selectShouldShowEvaluationButton,
} from 'store/slices/ai/ai'
import { Button } from 'ui-kit/button'
import { Divider } from 'ui-kit/divider'
import { Textarea } from 'ui-kit/textarea'
import { assertProd } from 'utils/assert-prod'
import { callSentry } from 'utils/sentry/call-sentry'

import {
  Action,
  ErrorMessage,
  MessageAction,
  MessageHistory,
  MessageItem,
  MessageRight,
  MessageText,
  TextAreaContainer,
  Wrapper,
} from './ask-ai-modal.styles'

const USER_PHOTO_FALLBACK_URL = 'https://via.placeholder.com/36?text=You'
const AI_URL = 'https://via.placeholder.com/36x36/00796b/ffffff/?text=AI'

interface AskAiModalProps {
  handleClose: () => void
}

export const AskAiModal = ({ handleClose }: AskAiModalProps) => {
  useLoadAiMessageCountLast3Hours()
  const theme = useAppTheme()
  const dispatch = useAppDispatch()
  const authUser = useAuthUser()

  const { displayName: photoURL, id: uid } = authUser || {}

  const messages = useAppSelector(selectAiMessages)
  const messagesLoading = useAppSelector(selectAiMessagesLoading)
  const messageCountLast3Hours = useAppSelector(selectAiMessagesCountInLast3Hours)
  const shouldShowEvaluationButton = useAppSelector(selectShouldShowEvaluationButton)
  const shouldShowAiGuidedLessonButton = useAppSelector(selectShouldShowAiGuidedLessonButton)
  const aiError = useAppSelector(selectAiError)

  const userMessageRef = useRef<HTMLTextAreaElement>(null)

  const messageHistoryRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    assertProd(!!uid, 'User must be logged in to use the AI AskAiModal.')
  }, [uid])

  const getMessageBackground = (index: number) => ({
    backgroundColor: index % 2 ? theme.palette.background.default : theme.palette.background.paper,
    borderTop: index !== 0 ? `1px solid ${theme.palette.border.light}` : `none`,
  })

  const handleScrollToBottom = useCallback(() => {
    setTimeout(() => {
      if (!messageHistoryRef?.current) return
      messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight
    }, 100)
  }, [])

  useEffect(() => {
    handleScrollToBottom()
  }, [handleScrollToBottom])

  const showTypingEffect = useRef(false)

  const handleSendMessage = useCallback(() => {
    const element = userMessageRef.current as HTMLTextAreaElement
    if (!element) return

    handleScrollToBottom()

    const userMessage = element.value

    if (userMessage.length > 1000) {
      const errorMessage = 'Message is too long, please keep it under 1000 characters.'

      callSentry({
        pageTag: SentryPageTag.askAIModal,
        message: errorMessage,
      })

      alert(errorMessage)

      return
    }

    if (userMessage) {
      showTypingEffect.current = true

      setTimeout(() => {
        if (!uid) return
        element.value = ''
        dispatch(askAi({ uid, userMessage }))
      }, 10)
    }
  }, [dispatch, handleScrollToBottom, uid])

  return (
    <Wrapper>
      <Typography center variant="heading1">
        🤖 Ask TypingMentor AI
      </Typography>

      <Divider noBar wrapperThickness="1rem" />

      <MessageHistory ref={messageHistoryRef}>
        <div>
          {messages.map(({ role, content }, index) => {
            const isAi = role === 'assistant'
            const isAiLastMessage = role === 'assistant' && index === messages.length - 1

            const userPhotoUrl = photoURL || USER_PHOTO_FALLBACK_URL

            return (
              <MessageItem key={index} style={getMessageBackground(index)}>
                <img
                  className="ask-ai-message-item-avatar"
                  width={36}
                  height={36}
                  src={isAi ? AI_URL : userPhotoUrl}
                  key={isAi ? AI_URL : userPhotoUrl}
                  alt=""
                />

                <MessageRight>
                  <MessageText>
                    {isAiLastMessage && showTypingEffect.current ? (
                      <TypingEffect scrollContainerRef={messageHistoryRef} message={content} />
                    ) : (
                      <span>{content}</span>
                    )}
                  </MessageText>
                </MessageRight>
              </MessageItem>
            )
          })}

          {messagesLoading && (
            <MessageItem style={getMessageBackground(messages.length)}>
              <img width={36} height={36} src={AI_URL} key={AI_URL} alt="" />

              {messagesLoading && <span style={{ marginRight: '24px' }} />}
              <MessageRight>
                <MessageText>
                  <Loader name="ask-ai-ask-ai-modal" loading={messagesLoading}>
                    <span />
                  </Loader>
                </MessageText>
              </MessageRight>
            </MessageItem>
          )}

          {aiError && (
            <MessageItem style={getMessageBackground(messages.length)}>
              <img width={36} height={36} src={AI_URL} key={AI_URL} alt="" />

              <MessageRight>
                <MessageText>
                  <ErrorMessage>
                    Something went wrong! Please try again later. If the problem persists, please contact
                    support@typingmentor.com
                  </ErrorMessage>
                </MessageText>
              </MessageRight>
            </MessageItem>
          )}

          {messageCountLast3Hours >= MAX_MESSAGE_COUNT_IN_3_HOURS && (
            <MessageItem style={getMessageBackground(messages.length)}>
              <img width={36} height={36} src={AI_URL} key={AI_URL} alt="" />

              <MessageRight>
                <MessageText>
                  <ErrorMessage>
                    You&apos;ve reached the message limit of {MAX_MESSAGE_COUNT_IN_3_HOURS} messages for the last 3
                    hours. Please try again later.
                  </ErrorMessage>
                </MessageText>
              </MessageRight>
            </MessageItem>
          )}
        </div>
      </MessageHistory>

      <Action>
        <MessageAction>
          {shouldShowEvaluationButton && (
            <div onClick={handleClose}>
              <CtaLesson
                cta={LESSON_NAMES.AI}
                href={PAGES['/typing-practice/']}
                title="Go to AI lessons practice page"
                buttonView
                blank={false}
              >
                {EVALUATION_BUTTON_TEXT}
              </CtaLesson>
            </div>
          )}

          {shouldShowAiGuidedLessonButton && (
            <div onClick={handleClose}>
              <CtaLesson
                cta={LESSON_NAMES.AI}
                href={PAGES['/typing-practice/']}
                title="Go to AI lessons practice page"
                buttonView
                blank={false}
              >
                {AI_GUIDED_LESSON_BUTTON_TEXT}
              </CtaLesson>
            </div>
          )}
        </MessageAction>

        {messages.length === 0 && (
          <Typography center className="ask-ai-message-limit-caption" variant="smallText2">
            TypingMentor AI currently has a cap of 25 messages in the last 3 hours.
          </Typography>
        )}

        <TextAreaContainer>
          <Textarea
            autoFocus
            noResize
            placeholder="Send a message..."
            ref={userMessageRef}
            onKeyDown={(event) => event.key === 'Enter' && !event.shiftKey && handleSendMessage()}
          />

          <Button wrapperClass="send-ask-ai-message" onClick={handleSendMessage}>
            Send
          </Button>
        </TextAreaContainer>
      </Action>
    </Wrapper>
  )
}
