import { PAGES } from 'constants/routes'
import { PageRoute } from 'interface/routes'

/**
 * use this util if you need current page's page route like we have values the PAEGS in 'constants/routes'
 * @param routerAsPath
 * @returns
 */
export const getPageRoute = (routerAsPath: string) => {
  const hashLessUrl = routerAsPath.split('#')[0]

  // format paramAndHashLessUrl in a way so that it returns exact same values as we have in the PAEGS in 'constants/routes'
  const paramAndHashLessUrl = hashLessUrl.split('?')[0]

  // format paramAndHashLessUrl in a way so that it returns exact same values as we have in the PAEGS in 'constants/routes'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return PAGES[paramAndHashLessUrl] as PageRoute
}
