import { useState } from 'react'

import { Wrapper } from './TypingSpeed.styles'

export const TypingSpeed = ({ wpm, kph, showKph }: { wpm: number; kph: number; showKph: boolean }) => {
  const [preferKph, setPreferKph] = useState(showKph)

  return (
    <Wrapper onClick={() => setPreferKph((prev) => !prev)} title="Click to toggle between KPH and WPM">
      {preferKph ? (
        <>
          <sup>kph</sup>
          <span>{kph.toFixed()}</span>
        </>
      ) : (
        <>
          <sup>wpm</sup>
          <span data-cy="pg-with-controls-typing-end-result-speed">{wpm.toFixed()}</span>
        </>
      )}
    </Wrapper>
  )
}
