import { TRAINING_NAMES } from 'constants/training'
import { usePersistedState } from 'context-providers/persisted-state'
import ReactDOM from 'react-dom'
import { usePopperTooltip } from 'react-popper-tooltip'
import { isClient } from 'utils/is-client'

/**
 * Use this hooks if you want to add another training tooltip for the user.
 * @param param0
 * @returns following things -
 * triggerRef: that you need to pass to your tooltip trigger as <button ref={triggerRef}>...</button>
 * isTrained: boolean
 * setTrained: to set the boolean. Also you can directly use the usePersistedState.setTrainedOnEscForNext()
 * DOM element as a React portal that contains the tooltip balloon
 */
export const useTraining = ({
  tooltipMessage,
  trainingName,
}: {
  tooltipMessage: string
  trainingName: TRAINING_NAMES
}) => {
  // you need to add both the setter and getter on usePersistedState when adding each new training item
  const persistedState = usePersistedState()

  // you need to add both the setter and getter on usePersistedState when adding each new training item
  const isTrained = {
    [TRAINING_NAMES.ESC_FOR_NEXT]: persistedState.trainedOnEscForNext,
  }[trainingName]

  // you need to add both the setter and getter on usePersistedState when adding each new training item
  const setIsTrained = {
    [TRAINING_NAMES.ESC_FOR_NEXT]: persistedState.setTrainedOnEscForNext,
  }[trainingName]

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    visible: !isTrained,
    placement: 'top',
    offset: [0, 8],
  })

  return {
    setTriggerRef,
    setIsTrained,
    isTrained,
    balloon: (
      <>
        {isClient() &&
          visible &&
          ReactDOM.createPortal(
            <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
              <div>{tooltipMessage}</div>
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>,
            document.body
          )}
      </>
    ),
  }
}

/** [USAGE]

const [triggerRef, setTrained, isTrained, element] = useTraining({
  tooltipMessage: 'Press Esc to load next',
  trainingName: TRAINING_NAMES.ESC_FOR_NEXT,
})

<button
  ref={triggerRef}
  onClick={() => setTrained(true)}
>
  Click here

  // Following element is important. It injects the portal for tooltip balloon
  {element}
</button>

*/
