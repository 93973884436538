import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { getUserRef } from 'config/database/refs'
import firebase from 'firebase/compat/app'
import { AiSubscription, DB_REF } from 'interface/database'
import { __setAiSubscription, setAiSubscriptionLoading } from 'store/slices/subscription/subscription'
import { assertProd } from 'utils/assert-prod'

/**
 * This utils syncs up the redux store with firebase store for user's message count to the askAi feature.
 * To get it working on a page, you need to call useLoadAiMessageCountLast3Hours() hooks immediately
 * inside of authentication container, e.g. src\views\home\home.tsx
 * @param dispatch
 * @param uid
 */
export const loadAiSubscription = async (dispatch: Dispatch<AnyAction>, uid?: string) => {
  assertProd(!!uid, 'uid is required. (loadAiSubscription)')

  if (!uid) return

  dispatch(setAiSubscriptionLoading(true))

  const fireDb = await import('firebase/database')
  const { getDatabase, onValue, ref } = fireDb
  const db = getDatabase(firebase.app())

  const propertyRef = ref(db, getUserRef(uid, { dbRef: DB_REF.aiSubscription }))

  onValue(
    propertyRef,
    (snapshot) => {
      const aiSubscription = snapshot.val() as AiSubscription

      if (!aiSubscription) {
        dispatch(
          __setAiSubscription({
            type: null,
            expiresAt: 0,
          })
        )
        return
      }

      dispatch(__setAiSubscription(aiSubscription))
    },
    (error) => {
      console.error('Error reading Firebase data: ', error)
    }
  )
}
