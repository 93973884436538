import { PageRoute } from 'interface/routes'
import { Rules } from 'store/slices/pg-slice/interface'
import store from 'store2'

const PG_LESSON_NAME = 'pgLessonName'

export const setLocalStoreLessonRules = ({ pageRoute, rules }: { pageRoute: PageRoute; rules: Partial<Rules> }) => {
  const prev = store(PG_LESSON_NAME) || {}
  const updated = { ...prev, [pageRoute]: rules }
  store(PG_LESSON_NAME, updated)
}

export const getLocalStoreLessonRules = ({
  pageRoute,
  defaultRules,
}: {
  pageRoute: PageRoute
  defaultRules: Partial<Rules>
}): Partial<Rules> => {
  const prev = store(PG_LESSON_NAME) || {}
  return prev[pageRoute] || defaultRules
}
