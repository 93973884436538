import { getLocalStoreLessonRules } from 'local-store/lesson'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { Rules } from 'store/slices/pg-slice/interface'
import { getPageRoute } from 'utils/get-page-route'

/**
 * Use this hooks in the case you want to know the name of the lesson for a specific page, e.g. /typing-practice/
 *
 * How it works: it looks into the local storage for previously set lesson rule for for a specific route e.g. /typing-practice/
 * If no previous record found, then it returns the default lesson name for that route. It is useful for the typing-practice
 * page where user can select different lessons name from the lessons page and redirect the user to the typing-practice
 * page with the clicked lesson item as a default rule for typing-practice page so that even user reloads the page
 * later, the selected lesson type persists. And when we use <Pg name="main" ... /> from the CMS as a CTA, we don't
 * set any lesson rule on the local storage for the article/[slug]/ pages so that it renders whatever we set from CTA as a
 * default lesson name.
 *
 * @param props
 * @returns
 */
export const usePageBasedLessonRule = (props: { defaultRules: Partial<Rules> }) => {
  const { defaultRules } = props
  const defaultLessonName = defaultRules.lessonName
  const defaultDuration = defaultRules.duration

  const router = useRouter()
  const routerAsPath = router.asPath

  const getPageBasedLessonRules = useCallback(() => {
    return getLocalStoreLessonRules({
      defaultRules: {
        lessonName: defaultLessonName,
        duration: defaultDuration,
      },
      pageRoute: getPageRoute(routerAsPath),
    })
  }, [defaultDuration, defaultLessonName, routerAsPath])

  return getPageBasedLessonRules
}
