import { PG_BORDER_RADIUS } from 'constants/pg'
import { css } from 'styled-components'

export const copyHolderWrapperStyles = css`
  display: block;
  overflow: hidden;
  position: relative;
  height: 272px;
  font-size: 1.7rem;
  line-height: 1.6;
  font-family: monospace;
  border-radius: ${({ theme }) => theme.borderRadius[PG_BORDER_RADIUS]};
  background-color: ${({ theme }) => theme.palette.background.paper};
`
