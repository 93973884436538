import { PG_BORDER_RADIUS } from 'constants/pg'
import { css } from 'styled-components'

export const copyHolderPauseStyles = css<{ cursorNotAllowed?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: ${({ theme }) => theme.borderRadius[PG_BORDER_RADIUS]};
  z-index: 10;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%);
  ${({ theme }) =>
    theme.palette.mode === 'dark' &&
    css`
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    `};

  font-size: 1.5rem;
  padding: 1rem;

  ${({ cursorNotAllowed }) => cursorNotAllowed && `cursor: not-allowed;`};

  & > .click-here-text {
    padding: 1rem;
    border: 3px solid currentColor;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => (theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main)};
  }
`
