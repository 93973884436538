import { LESSON_NAME } from 'constants/lessons'
import { PG } from 'constants/pg'
import { useAppDispatch } from 'hooks/use-app-dispatch'
import { setLocalStoreLessonRules } from 'local-store/lesson'
import Link from 'next/link'
import { ReactNode, useCallback } from 'react'
import toast from 'react-hot-toast'
import { pgSlices } from 'store/slices/pg-slice'
import { conditionalProp } from 'utils/conditional-prop'
import { relativeUrl } from 'utils/relative-url'

import { CtaButton, LinkStyled } from './CtaLesson.styles'

export const CtaLesson = (props: {
  cta: string // LESSON_NAME
  href: string
  title: string
  buttonView: boolean
  blank: boolean
  children: ReactNode
}) => {
  const { cta, href, title, buttonView, blank, children } = props

  const mainPgSlice = pgSlices[PG.MAIN_PG]
  const dispatch = useAppDispatch()

  const generateLesson = useCallback(
    (lessonName: LESSON_NAME) => {
      // Set local storage for loading correct lesson name on reload
      setLocalStoreLessonRules({
        pageRoute: '/typing-practice/',
        rules: { lessonName },
      })

      dispatch(
        mainPgSlice.actions.handleRestart({
          pgName: PG.MAIN_PG,
          rules: { lessonName },
        })
      )

      console.log('=-=-=-=-=-=-=-= CtaLesson.tsx =-=-=-=-=-=-=-=-=')
    },
    [dispatch, mainPgSlice.actions]
  )

  const LinkOnly = (
    <Link href={relativeUrl(href)} passHref legacyBehavior prefetch={false}>
      <LinkStyled
        {...conditionalProp(blank, 'target', '_blank')}
        rel="noopener noreferrer"
        onClick={(e) => {
          if (e.ctrlKey) {
            e.preventDefault()
            toast.error(`Click without pressing "Ctrl" key.`)
            return false
          }
          generateLesson(cta as LESSON_NAME)
        }}
        title={title}
      >
        {children}
      </LinkStyled>
    </Link>
  )

  if (!buttonView) {
    return LinkOnly
  }

  return <CtaButton>{LinkOnly}</CtaButton>
}
