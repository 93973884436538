import { Loader } from 'components/loader'
import type { PgProps } from 'components/pg'
import { RenderWithDelay } from 'components/render-with-delay'
import { Typography } from 'components/typography'
import { WithAds } from 'components/with-ads'
import { LESSON_DURATION, LESSON_NAME, LESSON_NAMES, LESSON_TITLE } from 'constants/lessons'
import { PG } from 'constants/pg'
import { PAGES } from 'constants/routes'
import { useDevice } from 'context-providers/divice-provider'
import { useLoadInitialData } from 'hooks/database/use-load-initial-data'
import { usePageBasedLessonRule } from 'hooks/lesson/use-page-based-lesson-rule'
import { useAppDispatch } from 'hooks/use-app-dispatch'
import { useAppSelector } from 'hooks/use-app-selector'
import { setLocalStoreLessonRules } from 'local-store/lesson'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useAuthUser } from 'next-firebase-auth'
import { useCallback, useEffect, useState } from 'react'
import { pgSelectors, pgSlices } from 'store/slices/pg-slice'
import { Rules } from 'store/slices/pg-slice/interface'
import { selectApp } from 'store/slices/setting'
import { selectNextTestForInitialEvaluation } from 'store/slices/stat/stat'
import {
  selectAiSubscriptionLoaded,
  selectAiSubscriptionLoading,
  selectIsAiSubscriptionActive,
  selectIsAiSubscriptionExpired,
} from 'store/slices/subscription/subscription'
import { Checkbox } from 'ui-kit/checkbox'
import { Divider } from 'ui-kit/divider'
import { InternalAlert } from 'ui-kit/internal-alert/internal-alert'
import { InternalLink } from 'ui-kit/internal-Link'
import { wpmToKeysPerHour } from 'utils/calculator/keys-per-hour'
import { getPageRoute } from 'utils/get-page-route'

import { LoginAlert } from './components/login-alert'
import { StrokeAnalysis } from './components/stroke-analysis'
import { TypingAction } from './components/typing-action'
import { ResultNumber, TypingEndResult, TypingRemarks } from './components/typing-end-result'
import { TypingSpeed } from './components/typing-speed'
import {
  CopyHolderWrapper,
  OneMinute,
  OneMinuteWrapper,
  PauseNotification,
  PgHeading,
  PgHeadingWrapper,
  PgWrapper,
  PgWrapperInside,
  Progress,
  ProgressBar,
} from './pg-with-controls.styles'

const Pg = dynamic<PgProps>(() => import('components/pg').then((mod) => mod.Pg), {
  ssr: false,
  loading: () => <Loader variant="absolute" loading={true} name="components-pg-with-controls" />,
})

const DynamicFireworksAfterTestEnd = dynamic<unknown>(
  () => import('./components/fireworks-after-test-end').then((mod) => mod.FireworksAfterTestEnd),
  { ssr: false }
)

const TypingHands = dynamic<{ activeChar?: string }>(
  () => import('./components/typing-hands').then((mod) => mod.TypingHands),
  {
    ssr: false,
  }
)

interface PgWithControlsProps {
  pgName: PG

  // defaultRules works as a fallback if nothing found from usePageBasedLessonRule() hook call below
  defaultRules: Partial<Rules>

  // in the case of article pages we force no authentication and show sign in / sign up button
  forceNoAuth?: boolean

  // isTypingPracticePage is required to render H1 tag for typing-practice page since this page has no other heading for SEO
  isTypingPracticePage?: boolean
}

export const PgWithControls = (props: PgWithControlsProps) => {
  const { pgName, defaultRules, forceNoAuth, isTypingPracticePage } = props

  const router = useRouter()
  const pageRoute = getPageRoute(router.asPath)

  useLoadInitialData()

  const { isTouchDevice } = useDevice()

  const authUser = useAuthUser()
  const authLoading = !authUser.clientInitialized

  const setting = useAppSelector(selectApp)

  const slice = pgSlices[pgName]
  const pgSelector = pgSelectors[pgName]

  const dispatch = useAppDispatch()

  // pgState should be unique in all the instance of playground based on the name of the playground e.g. name: "main-pg"
  const pgState = useAppSelector(pgSelector)

  const { showProgressBar, showLiveStat, showTypingHands } = setting

  const { timeElapsed, rules, duration, activeIndex, text, accuracy, wpm, strokes, finished, copyLoading } = pgState

  const [userClicked, setUserClicked] = useState(false)

  const oneMinCheckboxDisabled = copyLoading || isTouchDevice || !userClicked
  const nextNotAllowed = isTouchDevice || !userClicked

  const handleOneMinute = useCallback(() => {
    if (oneMinCheckboxDisabled) return

    const durationToSet = duration === LESSON_DURATION.ONE_MIN ? 0 : LESSON_DURATION.ONE_MIN

    // Set local storage for loading correct lesson name on reload
    setLocalStoreLessonRules({
      pageRoute,
      rules: {
        lessonName: rules.lessonName,
        duration: durationToSet,
      },
    })

    dispatch(
      slice.actions.handleRestart({
        pgName,
        rules: {
          lessonName: rules.lessonName,
          duration: durationToSet,
        },
      })
    )
  }, [oneMinCheckboxDisabled, pageRoute, rules.lessonName, dispatch, slice.actions, pgName, duration])

  const handleNext = useCallback(() => {
    if (copyLoading || isTouchDevice) return

    // Set local storage for loading correct lesson name on reload
    setLocalStoreLessonRules({
      pageRoute,
      rules: { lessonName: rules.lessonName },
    })

    dispatch(
      slice.actions.handleRestart({
        rules: {
          lessonName: rules.lessonName,
          duration,
        },
        pgName,
      })
    )
  }, [dispatch, duration, isTouchDevice, pageRoute, pgName, copyLoading, rules.lessonName, slice.actions])

  const getPageBasedLessonRules = usePageBasedLessonRule({ defaultRules })

  // we need pageBasedLessonName to render on the client side in useEffect to avoid server-html vs client-html mismatch error
  const [pageBasedLessonName, setPageBasedLessonName] = useState<LESSON_NAME>('PARAGRAPH')

  useEffect(() => {
    const name = LESSON_TITLE[getPageBasedLessonRules()?.lessonName || 'PARAGRAPH'] as LESSON_NAME
    setPageBasedLessonName(name)
  }, [getPageBasedLessonRules])

  const aiSubscriptionLoaded = useAppSelector(selectAiSubscriptionLoaded)
  const aiSubscriptionLoading = useAppSelector(selectAiSubscriptionLoading)
  const isAiSubscriber = useAppSelector(selectIsAiSubscriptionActive)
  const isAiSubscriptionExpired = useAppSelector(selectIsAiSubscriptionExpired)

  const isAiLesson = pageBasedLessonName === LESSON_TITLE[LESSON_NAMES.AI]

  const isAiLessonAndSubscriptionLoading = isAiLesson && aiSubscriptionLoading

  const isAiLessonButNotAllowedToAccessAi =
    isAiLesson && ((aiSubscriptionLoaded && !isAiSubscriber) || (!authLoading && !authUser.id))

  // _activeRules to exclude _activeRules.text from activeRules
  const _activeRules = userClicked ? rules : defaultRules
  // if userClicked on the PauseNotification to load the first lesson, then we pass the pgState.rules, otherwise pass defaultRules
  const activeRules: Partial<Rules> = {
    lessonName: _activeRules.lessonName,
    duration: _activeRules.duration,
  }

  const isInitialEvaluation = useAppSelector(selectNextTestForInitialEvaluation)

  // case 1: We blocked it, but if accidentally someone visits the AI lesson page, we push them to the lessons page so that they can not come back to the ai guided lesson without signing in and active subscription
  // case 2: If AI sub logs out, push them to the lessons page so that they can not come back to the ai guided lesson without signing in and active subscription
  if (!authLoading && !authUser.id && isAiLesson) {
    router.push(PAGES['/lessons/'])
  }

  return (
    <>
      {finished && <DynamicFireworksAfterTestEnd />}

      <PgWrapper>
        {showProgressBar && (
          <ProgressBar>
            <Progress
              forDuration={!!duration}
              progress={
                duration ? +(timeElapsed / duration).toFixed(6) * 100 : +(activeIndex / text.length).toFixed(6) * 100
              }
            />
          </ProgressBar>
        )}

        <PgWrapperInside>
          {isAiLessonButNotAllowedToAccessAi && !isAiSubscriptionExpired && (
            <>
              <InternalAlert>
                🚨 Oops! It looks like you haven&apos;t subscribed yet! 😯 To access the amazing 🤖 &quot;Ask AI&quot;
                🧠 feature, please{' '}
                <InternalLink href={PAGES['/ask-chat-gpt/']}>
                  <b style={{ textDecoration: 'underline' }}>subscribe now! </b>
                </InternalLink>
                🌟🔥 Enjoy endless AI fun! 🎉😄
              </InternalAlert>
              <Divider noBar />
            </>
          )}

          <WithAds hideAds={isAiLesson ? isAiSubscriber || !aiSubscriptionLoaded : false}>
            <OneMinuteWrapper>
              <PgHeadingWrapper>
                <PgHeading as={isTypingPracticePage ? 'h1' : 'span'}>Typing practice</PgHeading>:{' '}
                <InternalLink
                  title="Go to lessons page"
                  className="gtm-pg-with-controls-link-to-lessons-page"
                  data-cy="pg-with-controls-link-to-lessons-page"
                  href={PAGES['/lessons/']}
                >
                  {pageBasedLessonName}
                </InternalLink>
              </PgHeadingWrapper>

              <OneMinute
                isDisabled={oneMinCheckboxDisabled}
                onClick={handleOneMinute}
                title="Set test duration to 1 minute"
              >
                <Checkbox
                  indeterminate={!userClicked}
                  wrapperClass="pg-action-one-minute"
                  handleClick={() => ''}
                  disabled={oneMinCheckboxDisabled}
                  // if userClicked on the PauseNotification to load the first lesson,
                  // then we pass the pgState.rules.duration, otherwise pass defaultRules.duration
                  checked={(userClicked ? duration : defaultRules.duration) === LESSON_DURATION.ONE_MIN}
                />
                <span>One minute</span>
              </OneMinute>
            </OneMinuteWrapper>

            <Divider noBar wrapperThickness=".5rem" />

            <CopyHolderWrapper>
              {!userClicked && (
                <Loader
                  variant="absolute"
                  name="pg-with-controls-pause-notification"
                  loading={isAiLessonAndSubscriptionLoading}
                >
                  <PauseNotification data-cy="pg-with-controls-pause-notification" onClick={() => setUserClicked(true)}>
                    <div className="click-here-text">▶ Click here</div>
                  </PauseNotification>
                </Loader>
              )}

              {userClicked && isTouchDevice && (
                <PauseNotification cursorNotAllowed>
                  <div>Please use</div>
                  <div>Typing Mentor</div>
                  <div>from a non-touch device.</div>
                </PauseNotification>
              )}

              {userClicked && isAiLessonButNotAllowedToAccessAi && (
                <div style={{ padding: '1rem', paddingTop: '3rem', textAlign: 'center' }}>
                  Until you have access to the AI feature, you may practice the other lessons
                  <InternalLink href={PAGES['/lessons/']}> here.</InternalLink>
                </div>
              )}

              {userClicked && !isTouchDevice && !isAiLessonButNotAllowedToAccessAi && (
                <Pg name={pgName} autoFocus defaultRules={getPageBasedLessonRules()} />
              )}
            </CopyHolderWrapper>

            <Divider noBar />

            <TypingEndResult>
              <>
                <ResultNumber data-cy="pg-with-controls-typing-end-result-result-number">
                  {(showLiveStat || finished) && (
                    <>
                      <span data-cy="pg-with-controls-typing-end-result-accuracy">{(accuracy * 100).toFixed(1)}</span>
                      <sup>%accuracy</sup>
                    </>
                  )}
                </ResultNumber>
                <TypingRemarks>{showTypingHands && <TypingHands activeChar={text[activeIndex]} />}</TypingRemarks>
                <ResultNumber>
                  {(showLiveStat || finished) && (
                    <TypingSpeed
                      showKph={router.asPath.includes(PAGES['/articles/keystrokes-per-hour-test/'])}
                      kph={wpmToKeysPerHour(activeIndex, timeElapsed)}
                      wpm={wpm}
                    />
                  )}
                </ResultNumber>
              </>
            </TypingEndResult>

            <Divider noBar />

            <Divider noBar />

            <TypingAction
              forceNoAuth={forceNoAuth}
              nextNotAllowed={nextNotAllowed}
              nextIsLoading={!nextNotAllowed && copyLoading}
              handleNext={handleNext}
              typingFinished={finished}
              rules={activeRules}
              isAiLesson={isAiLesson}
            />

            {finished && !authUser.id && (
              <>
                <Divider noBar />
                <LoginAlert rules={activeRules} />
              </>
            )}

            <RenderWithDelay shouldDisplayContent={isAiLesson && !!isInitialEvaluation} delayBeforeDisplay={3000}>
              <Divider noBar />
              <Divider />
              <Typography>
                📝 You are currently participating in our Initial Evaluation phase:{' '}
                <b>{isInitialEvaluation?.toLowerCase()} test. </b>
                We appreciate your effort! 💪
              </Typography>
              <Divider />
            </RenderWithDelay>

            <Divider noBar />

            {finished && (
              <StrokeAnalysis
                durationDecimalPlace={
                  router.asPath.includes(PAGES['/articles/how-fast-can-you-type-the-alphabet/']) ? 2 : 0
                }
                duration={(duration || timeElapsed) / 1000}
                strokes={strokes}
              />
            )}

            <Divider noBar />
          </WithAds>
        </PgWrapperInside>
      </PgWrapper>

      {/* tawk.to */}
      {isAiSubscriber && (
        <Script
          id="tawk-to-init"
          type="text/javascript"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
              (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/64a14b1dcc26a871b025d82c/1h4b0qr06';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
              })();
          `,
          }}
        />
      )}
    </>
  )
}
