import styled, { css } from 'styled-components'

const centerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Wrapper = styled.div<{ center?: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: overlay;
  text-align: center;

  ${({ center }) => center && centerStyles}
`

const fluidStyles = css`
  width: 100vw;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.default};
`

const nonFluidStyles = css`
  display: inline-block;
  margin: 4rem;
  box-shadow: 0 0 8px #444;
  border-radius: 0.5rem;
`

export const ModalContent = styled.div<{ fluid?: boolean }>`
  text-align: left;
  overflow: hidden;

  ${({ fluid }) => (fluid ? fluidStyles : nonFluidStyles)}
`

export const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 3rem;
  top: 3rem;
  width: 34px;
  height: 36px;
  opacity: 1;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: ${({ theme }) => theme.palette.text.primary};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`
