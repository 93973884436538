import { Strokes } from 'store/slices/pg-slice/interface'

export const getStrokeAnalysis = (strokes: Strokes) => {
  const all = Object.values(strokes || {})
  const typedLastChar = !!all[all.length - 1]?.actuallyTypedCharacter
  const typed = typedLastChar ? all : all.slice(0, -1)

  let correct = 0
  let wrong = 0

  for (let index = 0; index < typed.length; index++) {
    const element = typed[index]

    element.attempts.forEach((attempt) => {
      // if correct attempt
      if (attempt.typedChar === element.characterToBeTyped) correct++
      // else wrong attempts
      else wrong++
    })
  }

  return { correct, wrong }
}
