import { ReactNode } from 'react'

import { Success } from './internal-alert.styled'

export const InternalAlert = ({
  children,
  variant = 'success',
}: {
  children: ReactNode
  variant?: 'success' | 'warn' | 'danger'
}) => {
  switch (variant) {
    case 'success': {
      return <Success>{children}</Success>
    }

    default: {
      throw new Error('Invalid variant for InternalAlert')
    }
  }
}
