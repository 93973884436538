import styled, { css } from 'styled-components'

import { TypographyProps } from './interface'

export const TypographyStyled = styled.span<Pick<TypographyProps, 'variant' | 'as' | 'color' | 'bold' | 'center'>>`
  ${({ theme, variant }) => theme.typography[variant || 'body1']};
  color: ${({ theme, color }) => theme.palette.text[color || 'primary']};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `};

  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
`
