import React, { forwardRef, ReactNode, TextareaHTMLAttributes } from 'react'

import { TextareaStyled } from './Textarea.styles'

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: ReactNode
  value?: string
  noResize?: boolean
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { children, value } = props
  return (
    <TextareaStyled {...props} ref={ref} value={value}>
      {children}
    </TextareaStyled>
  )
})

Textarea.displayName = 'Textarea'
