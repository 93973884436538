import styled from 'styled-components'

export const TypingActionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * + * {
    margin-left: 1rem;
  }
`

export const AskChatGptImageAction = styled.div`
  width: 178px;
  height: auto;
  margin-left: auto;
  cursor: pointer;
  position: relative;

  & .closeIcon {
    display: none;
  }

  &:hover .closeIcon {
    display: flex;
    position: absolute;
    top: -19px;
    right: 8px;
    width: 20px;
    height: 20px;
    background-color: ${({ theme }) => theme.palette.background.paper};

    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    line-height: 1;
    padding-bottom: 7px;
    font-size: 1.5rem;

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: #fff;
    }
  }
`
