import { useMemo } from 'react'

import { TypographyProps } from './interface'
import { TypographyStyled } from './typography.styles'

export const Typography = (props: TypographyProps) => {
  const variant = props.variant || 'body1'

  const as = useMemo(() => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      {
        heading1: 'h1',
        heading2: 'h2',
        heading3: 'h3',
        heading4: 'h4',
        body1: 'p',
        body2: 'p',
        smallText: 'p',
        smallText2: 'p',
        button: 'p',
      }[variant] || 'span'
    )
  }, [variant])

  return (
    <TypographyStyled
      className={props.className}
      {...({
        variant,
        as,
        color: props.color || 'primary',
        bold: props.bold || false,
        center: props.center || false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)}
    >
      {props.children}
    </TypographyStyled>
  )
}
