import styled from 'styled-components'

export const StrokeAnalysisWrapper = styled.div`
  max-width: 38.75rem;
  height: 6.25rem;
  display: flex;
`

export const Duration = styled.div`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.primary.main};
  width: 7.75rem;
  min-width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StrokeStat = styled.div`
  &:nth-child(2) {
    text-align: right;
  }
  & > div:nth-child(2) {
    font-size: 1.375rem;
  }
`

export const StrokePanel = styled.div`
  padding: 0.5rem;
  color: ${({ theme }) => theme.palette.text.primary};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.primary.main};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 31rem;
  flex-wrap: wrap;
  height: 100%;
`

export const RatioBar = styled.div<{ percentage: number }>`
  height: 1.25rem;
  background: repeating-linear-gradient(
    -50deg,
    #fff 1px,
    ${({ theme }) => theme.palette.primary.main} 2px,
    ${({ theme }) => theme.palette.primary.main} 5px,
    #fff 6px,
    #fff 10px
  );
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
  order: 0;
  width: 100%;

  &:after {
    content: '';
    height: 1.25rem;
    width: ${({ percentage }) => percentage && `${100 - percentage * 100}%`};
    background-color: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    right: 0;
    top: 0;
  }
`
