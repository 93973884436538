import { useState } from 'react'

const useToggle = () => {
  const [isOpen, setIsOpen] = useState<boolean>()

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    handleToggle,
    handleOpen,
    handleClose,
  }
}

export default useToggle
