import React, { useEffect, useRef, useState } from 'react'

/**
 * RenderWithDelay Component
 *
 * Purpose:
 * This component is used to conditionally render children with a delay. The delay can be set for both
 * displaying and hiding the children.
 *
 * Use Cases:
 * 1. An application might want to display a loading spinner for a certain amount of time before displaying content.
 * 2. It can be used to manage transition states in animations or reveal sections.
 * 3. When content needs to be fetched from an API and you want to show a placeholder until the content arrives.
 * 4. Showing a brief 'success' message after a user interaction, which disappears after a certain time.
 *
 * How it works:
 * The component accepts three props - `shouldDisplayContent`, `delayBeforeDisplay`, and `delayBeforeHide`.
 * `shouldDisplayContent` is a boolean which decides if the children should be displayed or not.
 * `delayBeforeDisplay` and `delayBeforeHide` are optional and they represent the delay in milliseconds
 * before displaying and hiding the children respectively.
 * If these delay props are not provided, the children are displayed or hidden immediately.
 * The delay is managed by JavaScript's `setTimeout` function.
 */
interface RenderDelayProps {
  shouldDisplayContent: boolean
  delayBeforeDisplay?: number
  delayBeforeHide?: number
  children: React.ReactNode
}

export const RenderWithDelay: React.FC<RenderDelayProps> = ({
  shouldDisplayContent,
  delayBeforeDisplay = null,
  delayBeforeHide = null,
  children,
}) => {
  const [contentVisibility, setContentVisibility] = useState(false)
  const timeoutHandle = useRef<NodeJS.Timeout | null>(null)

  // Clear any existing timeout when shouldDisplayContent changes
  useEffect(() => {
    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current)
    }
  }, [shouldDisplayContent])

  // Handle visibility for displaying content
  useEffect(() => {
    if (shouldDisplayContent) {
      if (delayBeforeDisplay !== null) {
        timeoutHandle.current = setTimeout(() => {
          setContentVisibility(true)
        }, delayBeforeDisplay)
      } else {
        setContentVisibility(true)
      }
    }
  }, [shouldDisplayContent, delayBeforeDisplay])

  // Handle visibility for hiding content
  useEffect(() => {
    if (!shouldDisplayContent) {
      if (delayBeforeHide !== null) {
        timeoutHandle.current = setTimeout(() => {
          setContentVisibility(false)
        }, delayBeforeHide)
      } else {
        setContentVisibility(false)
      }
    }
  }, [shouldDisplayContent, delayBeforeHide])

  // Only render children if contentVisibility is true
  return <>{contentVisibility && children}</>
}
