import { IS_DEV } from 'constants/app'
import { SITE_URL_ORIGIN } from 'constants/site'
import { setLocalStoreLessonRules } from 'local-store/lesson'
import { Rules } from 'store/slices/pg-slice/interface'
import { InternalLink } from 'ui-kit/internal-Link'

import { LoginAlertStyled } from './login-alert.styles'

export const LoginAlert = ({ rules }: { rules: Partial<Rules> }) => {
  const host = IS_DEV ? 'http://localhost:3000/' : SITE_URL_ORIGIN

  return (
    <LoginAlertStyled>
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 448 512"
        height="0.875rem"
        width="0.875rem"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path>
      </svg>
      &nbsp;&nbsp;
      <span>Your typing history is not being saved because you are not logged in!</span>
      &nbsp;
      <InternalLink
        href={`/login/?destination=${encodeURIComponent(`${host}typing-practice/`)}`}
        onClick={() => {
          // Set local storage for loading correct lesson name on reload
          setLocalStoreLessonRules({
            rules,
            pageRoute: '/typing-practice/',
          })
        }}
      >
        Sign in!
      </InternalLink>
    </LoginAlertStyled>
  )
}
