import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { getUserRef } from 'config/database/refs'
import dayjs from 'dayjs'
import firebase from 'firebase/compat/app'
import { DB_REF, DbSchema } from 'interface/database'
import { setMessageCountInLast3Hours } from 'store/slices/ai/ai'
import { assertProd } from 'utils/assert-prod'

/**
 * This utils syncs up the redux store with firebase store for user's message count to the askAi feature.
 * To get it working on a page, you need to call useLoadAiMessageCountLast3Hours() hooks immediately
 * inside of authentication container, e.g. src\views\home\home.tsx
 * @param dispatch
 * @param uid
 */
export const loadAiMessageCountLast3Hours = async (dispatch: Dispatch<AnyAction>, uid: string) => {
  assertProd(!!uid, 'uid is required. (loadAiMessageCountLast3Hours)')

  if (!uid) return

  const fireDb = await import('firebase/database')
  const { getDatabase, onValue, ref } = fireDb
  const db = getDatabase(firebase.app())

  const propertyRef = ref(db, getUserRef(uid, { dbRef: DB_REF.chatLoad }))

  onValue(
    propertyRef,
    (snapshot) => {
      // [DB_REF.chatLoad]: Record<string /* timestamps */, { length: number /* length of the message */ }>
      const chatLoad = snapshot.val() as DbSchema['chatLoad']

      // use dayjs to get the timestamp 3 hours ago
      const timestamp3HoursAgo = dayjs().subtract(3, 'hour').valueOf()

      // calculate the message count in last 3 hours
      const messageCountInLast3Hours = Object.keys(chatLoad || {}).filter((chatTimestamp) => {
        return parseInt(chatTimestamp, 10) > timestamp3HoursAgo
      }).length

      dispatch(setMessageCountInLast3Hours(messageCountInLast3Hours))
    },
    (error) => {
      console.error('Error reading Firebase data: ', error)
    }
  )
}
