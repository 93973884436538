import { css } from 'styled-components'

export const buttonBase = css`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: inherit;

  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
    background: none;
  }
`
