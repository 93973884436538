import styled from 'styled-components'

export const Wrapper = styled.div`
  cursor: help;

  &:hover {
    sup {
      font-weight: 900;
      transform: scale(1.2);
      transition: all 100ms;
    }
  }
`
