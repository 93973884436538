import styled, { css } from 'styled-components'
import { textareaStyles } from 'styles/textarea.styles'

import { Props } from './Textarea'

export const TextareaStyled = styled.textarea<Props>`
  ${textareaStyles}

  ${({ noResize }) =>
    noResize &&
    css`
      resize: none;
    `}
`
